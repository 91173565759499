import { Layout } from '@components/layout';
import { Breadcrumbs } from '@components/shared';
import { Posts } from '@components/standard';
import { IPage, IPostPage, IPost } from '@standardTypes';
import React from 'react';

interface IPageContext {
  pageContext: {
    page: IPage | IPostPage | IPost;
    posts: IPost[];
  };
}

const SingleCategory: React.FC<IPageContext> = ({
  pageContext
}): JSX.Element => {
  const { posts, page } = pageContext;
  return (
    <Layout seo={page.seo} siteMetaData={page.siteMetaData}>
      <Breadcrumbs
        elements={[
          {
            label: 'Blog',
            url: '/blog/'
          },
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            label: page.name,
            url: `/blog/categories/${page.slug}/`
          }
        ]}
      />
      <Posts posts={posts} />
    </Layout>
  );
};

export default SingleCategory;
